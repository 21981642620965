<template>
  <div class="bg-white">
    <the-app-bar />
    <div class="row">
      <div id="oonpay" class="col-sm-6 bg-img"></div>
      <div
        id="oonpay"
        class="col-sm-6 mb-20 flex flex-col justify-center items-center"
      >
        <h1 class="uppercase font-black text-4xl">Select Your Country</h1>
        <h4 class="uppercase font-black text-2xl">oonpay Business NetWork</h4>
        <p class="font-black text-xl">
          is only available in the following Countries
        </p>
        <div class="logo-container">
          <div class="row">
            <div class="col-md-6">
              <a class="btn" @click="setCountry('1')">
                <img
                  src="../../assets/images/Crowdfund/ghana.png"
                  height="80px"
                  width="80px"
                  alt=""
              /></a>
            </div>
            <div class="col-md-6">
              <a class="btn" @click="setCountry('2')">
                <img
                  src="../../assets/images/Crowdfund/nigeria.jpg"
                  height="80px"
                  width="80px"
                  alt=""
              /></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TheAppBar from "../../components/NavBar/TheAppBar.vue";
export default {
  components: { TheAppBar },
  methods:{
      setCountry(i){
         this.$store.dispatch("healthhub/setCountry",i)
         return this.$router.push("/crowdfund-account")
      }
  }
};
</script>

<style  scoped>
#oonpay {
  min-height: 70vh;
}

.flex-fill {
  flex: 1 1 auto;
}

.bg-img {
  background: url("../../assets/images/Crowdfund/People-World-Map-Featured.jpg");
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  object-fit: fill;
}

@media (max-width: 640px) {
  #oonpay {
    min-height: 50vh;
  }
}
</style>